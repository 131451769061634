import * as React from 'react'

import { PortalMode, useMode } from '~shared/hooks/useMode'

import DefaultUserDashboard from '@/dashboard/components/Dashboard/DefaultUserDashboard'
import NetworkViewDashboard from '@/dashboard/components/Dashboard/NetworkViewDashboard'
import OnboardingDashboard from '@/dashboard/components/Dashboard/OnboardingDashboard'
import { useMaincontractor } from '@/maincontractors/hooks/useMaincontractor'

import { AppPage } from '../types'

const DashboardMap: Record<PortalMode, React.FC> = {
  NAP: () => <NetworkViewDashboard />,
  WAP: () => <DefaultUserDashboard mode='WAP' />,
  MCP: () => {
    const mcStatus = useMaincontractor()?.status
    switch (mcStatus) {
      case 'ACTIVE':
        return <DefaultUserDashboard mode='MCP' />
      case 'ONBOARDING':
      default:
        return <OnboardingDashboard />
    }
  },
  undetermined: () => null,
}

const IndexPage: AppPage = () => {
  const mode = useMode()

  const Dashboard = React.useMemo(() => DashboardMap[mode], [mode])

  return <Dashboard />
}

export default IndexPage
