import type { QueryResult } from '@apollo/client'
import {
  GetDashboardStatsQuery,
  GetDashboardStatsQueryVariables,
  GetInactiveDriverStatsQuery,
  GetInactiveDriverStatsQueryVariables,
  useGetDashboardStatsQuery,
  useGetInactiveDriverStatsQuery,
} from '@wise/graphql'
import { isDefined } from '@wise/utils'
import { get } from 'lodash'
import * as React from 'react'

import type { EvidenceStats } from '@/evidence/components/EvidenceTiles'

export type StatType<T = number> = T | 'loading' | 'error'

interface DashboardStats {
  activeDrivers: StatType
  inactiveDrivers: StatType
  totalDrivers: StatType
  utrCoverage: StatType | null
  missingPaymentDetails: StatType
  paymentMethod: StatType | null
  migratedDrivers: StatType
  initialOnboardingApplications: StatType
  additionalOnboardingApplications: StatType
  onboardingPercentage: StatType
  totalApplications: StatType
  evidence: EvidenceStats
}

export const calcStats = (
  a: StatType,
  b?: StatType,
  method?: 'divide',
): StatType => {
  if (a === 'loading' || b === 'loading') {
    return 'loading'
  }
  if (a === 'error' || b === 'error') {
    return 'error'
  }
  b = b ?? 0
  switch (method) {
    case 'divide':
      return a / Math.max(1, b)
    default:
      return a + b
  }
}

export const getStat = <T>(
  source: T,
  getter: (o: T) => number | undefined,
): StatType => {
  const value = getter(source)
  if (typeof value === 'number') return value
  if (get(source, 'loading')) return 'loading'
  return 'error'
}

export const getDashboardStats = (
  main: QueryResult<GetDashboardStatsQuery, GetDashboardStatsQueryVariables>,
  inactive: QueryResult<
    GetInactiveDriverStatsQuery,
    GetInactiveDriverStatsQueryVariables
  >,
): DashboardStats => {
  const expires = {
    requested: getStat(
      main,
      (i) => i.data?.expirationStats?.dueToExpireRequested,
    ),
    total: getStat(main, (i) => i.data?.expirationStats?.dueToExpire),
  }
  const expiresPercentage = calcStats(
    expires.requested,
    expires.total,
    'divide',
  )

  const expired = {
    requested: getStat(main, (i) => i.data?.expirationStats?.expiredRequested),
    total: getStat(main, (i) => i.data?.expirationStats?.expired),
  }
  const expiredPercentage = calcStats(
    expired.requested,
    expired.total,
    'divide',
  )

  const expiresWithExpired = calcStats(expires.total, expired.total)

  const requested = getStat(main, (i) => i.data?.expirationStats?.requested)
  const reuploaded = getStat(main, (i) => i.data?.expirationStats?.uploaded)

  const activeDrivers = getStat(main, (i) => i.data?.dashboard?.activeDrivers)
  const inactiveDrivers = getStat(
    inactive,
    (i) => i.data?.dashboard?.inactiveDrivers,
  )
  const totalDrivers = getStat(main, (i) => i.data?.dashboard?.totalDrivers)
  const missingUtr = getStat(
    main,
    (i) => i.data?.dashboard?.activeWithUtrMissing,
  )
  const activeDriversWithProduct = getStat(
    main,
    (i) => i.data?.dashboard?.activeDriversWithProduct,
  )
  const missingPaymentDetails = getStat(
    main,
    (i) => i.data?.dashboard?.missingPaymentDetails,
  )
  const migratedDrivers = getStat(
    main,
    (i) => i.data?.dashboard?.migratedDrivers,
  )
  const initialOnboardingApplications = getStat(
    main,
    (i) => i.data?.dashboard?.ongoingApplications.initial,
  )
  const additionalOnboardingApplications = getStat(
    main,
    (i) => i.data?.dashboard?.ongoingApplications.additional,
  )

  const utrCoverage =
    typeof activeDrivers === 'number' && typeof missingUtr === 'number'
      ? activeDrivers === 0
        ? null
        : (activeDrivers - missingUtr) / activeDrivers
      : calcStats(activeDrivers, missingUtr)

  const paymentMethod =
    typeof activeDriversWithProduct === 'number' &&
    typeof activeDrivers === 'number'
      ? activeDrivers === 0
        ? null
        : Math.max(
            Math.min((activeDriversWithProduct ?? 0) / activeDrivers, 1),
            0,
          )
      : calcStats(activeDriversWithProduct, activeDrivers)

  const totalApplications = calcStats(
    initialOnboardingApplications,
    additionalOnboardingApplications,
  )

  const onboardingPercentage =
    typeof initialOnboardingApplications === 'number' &&
    typeof totalApplications === 'number'
      ? totalApplications === 0
        ? 0
        : Math.max(
            Math.min(initialOnboardingApplications / totalApplications, 1),
            0,
          )
      : calcStats(initialOnboardingApplications, totalApplications)

  return {
    activeDrivers,
    inactiveDrivers,
    totalDrivers,
    utrCoverage,
    missingPaymentDetails,
    paymentMethod,
    migratedDrivers,
    initialOnboardingApplications,
    additionalOnboardingApplications,
    onboardingPercentage,
    totalApplications,
    evidence: {
      expires: {
        ...expires,
        percentage: expiresPercentage,
      },
      expired: {
        ...expired,
        percentage: expiredPercentage,
      },
      requested: requested,
      reuploaded: reuploaded,
      expiresWithExpired,
    },
  }
}

export const useGetDashboardStats = (options: {
  mainContractorId: Optional<string>
  locationId: Optional<string>
}): DashboardStats => {
  const variables = React.useMemo<GetDashboardStatsQueryVariables>(() => {
    const variables = {
      locations: [options.locationId].filter(isDefined),
      mainContractors: [options.mainContractorId].filter(isDefined),
    }

    return {
      ...variables,
      legacyLocations: variables.locations,
      legacyMainContractors: variables.mainContractors,
      onlyLegacyData: false,
    }
  }, [options.locationId, options.mainContractorId])

  const mainQuery = useGetDashboardStatsQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    variables,
  })

  const inactiveQuery = useGetInactiveDriverStatsQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    variables,
  })

  return getDashboardStats(mainQuery, inactiveQuery)
}
