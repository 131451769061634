import { isDefined } from '@wise/utils'
import { AnimatePresence, motion } from 'framer-motion'
import Link from 'next/link'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import shallow from 'zustand/shallow'

import Dropdown from '~shared/components/Input/Dropdown/Dropdown'
import { PortalMode } from '~shared/hooks/useMode'
import { useSessionState } from '~shared/hooks/useSessionState'
import translateDriversTileDescriptionText from '~shared/i18n/translation-utils/default-user-dashboard/translateDriversTileDescriptionText'
import Dashboard from '~shared/layouts/Dashboard/Dashboard'
import PathTitle from '~shared/layouts/Dashboard/PathTitle'
import { trackAnalyticsEvent } from '~shared/services/analytics/analytics'
import { useUser } from '~shared/services/firebase/auth/hooks'
import { useGetMainContractorsWithDepots } from '~shared/utils/wap'

import MigratedStatTile from '@/dashboard/components/Dashboard/MigratedStatTile'
import { PieChartKey } from '@/dashboard/components/Dashboard/PieChartKey'
import PieChartTile from '@/dashboard/components/Dashboard/PieChartTile'
import StatTile from '@/dashboard/components/Dashboard/StatTile'
import { useGetDashboardStats } from '@/dashboard/utils/stats'
import { EvidenceTiles } from '@/evidence/components/EvidenceTiles'
import { useMaincontractor } from '@/maincontractors/hooks/useMaincontractor'

import { AppPage, isOption } from '~types'

interface Props {
  mode: PortalMode
}

const DefaultUserDashboard: AppPage<Props> = ({ mode }: Props): JSX.Element => {
  const userMainContractor = useMaincontractor()
  const user = useUser()
  const { t } = useTranslation()

  const filters = useSessionState(
    (store) => ({
      mainContractor: store.state.wap.viewPlatform,
      location: store.state.wap.viewLocation,
    }),
    shallow,
  )
  const setWapMainContractor = useSessionState(
    (store) => store.setWapViewPlatform,
  )
  const setWapLocation = useSessionState((store) => store.setWapViewLocation)

  const mainContractorId = React.useMemo(() => {
    if (userMainContractor?.id) return userMainContractor.id
    if (filters.mainContractor) return filters.mainContractor.id
    return null
  }, [filters.mainContractor, userMainContractor?.id])

  const { mainContractors, locations } = useGetMainContractorsWithDepots({
    mode,
    mainContractorId,
  })

  const { mainContractorOption, locationOption } = React.useMemo(
    () => ({
      mainContractorOption: mainContractors?.find(
        (mc) => mc.value === mainContractorId,
      ),
      locationOption: locations.find((loc) => loc.value === filters.location),
    }),
    [filters.location, locations, mainContractorId, mainContractors],
  )

  const params = React.useMemo<Parameters<typeof useGetDashboardStats>[0]>(
    () => ({
      locationId: filters.location || null,
      mainContractorId: mainContractorId,
    }),
    [filters.location, mainContractorId],
  )
  const stats = useGetDashboardStats(params)

  const getDescription = React.useMemo(() => {
    return translateDriversTileDescriptionText({
      mainContractorId,
      filtersLocation: filters.location,
      locationOptionlabel: locationOption?.label,
      mode,
      t,
      userMCName: userMainContractor?.name,
      mainContractorOptionLabel: mainContractorOption?.label,
    })
  }, [
    filters.location,
    locationOption?.label,
    mainContractorId,
    mainContractorOption?.label,
    mode,
    t,
    userMainContractor?.name,
  ])

  const dashboardTileClicked = React.useCallback(
    (tileName: string) => {
      trackAnalyticsEvent('dashboard_tile_clicked', {
        auth_user_id: user?.id ?? 'unknown',
        main_contractor_id: userMainContractor?.id ?? 'unknown',
        mode: mode,
        tile_name: tileName,
      })
    },
    [mode, user?.id, userMainContractor?.id],
  )

  React.useEffect(() => {
    // in case a Network has been selected and this page only displays MCs then set dropdown value to null
    if (!mainContractors?.some((mc) => mc.value === mainContractorId))
      setWapMainContractor(null)
  }, [mainContractorId, mainContractors, setWapMainContractor])

  const searchHandleChange = React.useCallback(
    (o) =>
      setWapMainContractor(
        isOption(o) ? { type: 'main-contractor', id: String(o.value) } : null,
      ),
    [setWapMainContractor],
  )
  const LocationHandleChange = React.useCallback(
    (o) => setWapLocation(isOption(o) ? String(o.value) : null),
    [setWapLocation],
  )
  const activeHandleClick = React.useCallback(
    () => dashboardTileClicked('active'),
    [dashboardTileClicked],
  )
  const inactiveHandleClick = React.useCallback(
    () => dashboardTileClicked('inactive'),
    [dashboardTileClicked],
  )
  const onboardingHandleClick = React.useCallback(
    () => dashboardTileClicked('onboarding'),
    [dashboardTileClicked],
  )
  const missingPaymentDetailsHandleClick = React.useCallback(
    () => dashboardTileClicked('missing_payment_details'),
    [dashboardTileClicked],
  )

  const hasMigratedDriversStats: boolean =
    typeof Number(stats.migratedDrivers) === 'number'
      ? Number(stats.migratedDrivers) > 0
      : false

  const mcName = mainContractorOption?.item.name || userMainContractor?.name

  return (
    <Dashboard>
      <div className='flex w-full flex-col'>
        <div className='flex flex-1 flex-col'>
          <div className='mb-6 flex flex-row items-center'>
            <PathTitle
              noMargins
              paths={[mcName, t('path_titles.dashboard')].filter(isDefined)}
            />
            <div className='ml-auto flex flex-row'>
              {mode === 'WAP' && mainContractors ? (
                <Dropdown
                  testId='dashboard-main-contractor-dropdown'
                  instanceId='dashboard-maincontractor'
                  options={mainContractors}
                  placeholder={t('generic.filters.all_main_contractors')}
                  className='mr-3'
                  option={mainContractorOption ?? null}
                  onChange={searchHandleChange}
                />
              ) : null}
              <Dropdown
                testId='dashboard-locations-dropdown'
                instanceId='dashboard-location'
                options={locations}
                option={locationOption}
                onChange={LocationHandleChange}
                placeholder={t(
                  'default_dashboard.all_locations_main_contractors_dropdown',
                )}
              />
            </div>
          </div>
          <div className='mb-4 grid grid-cols-1 items-stretch gap-4 sm:grid-cols-2 lg:grid-cols-3 xl:flex xl:flex-row'>
            <div className='flex-1' onClick={activeHandleClick}>
              <Link href={`/drivers/active`}>
                <a>
                  <StatTile
                    testId='dashboard-active-drivers-tile'
                    title={t('default_dashboard.active_drivers_stats_title')}
                    description={getDescription('active')}
                    colour='purple'
                    value={stats.activeDrivers}
                  />
                </a>
              </Link>
            </div>

            <div className='flex-1' onClick={inactiveHandleClick}>
              <Link href={`/drivers/inactive`}>
                <a>
                  <StatTile
                    testId='dashboard-inactive-drivers-tile'
                    title={t('default_dashboard.inactive_drivers_stats_title')}
                    description={getDescription('inactive')}
                    colour='grey-darker'
                    secondaryColour='black'
                    value={stats.inactiveDrivers}
                  />
                </a>
              </Link>
            </div>
            <div className='flex-1'>
              <StatTile
                testId='dashboard-total-drivers-tile'
                title={t('default_dashboard.total_drivers_stats_title')}
                description={getDescription('total')}
                colour='green'
                value={stats.totalDrivers}
              />
            </div>
            <div className='flex-1' onClick={missingPaymentDetailsHandleClick}>
              <Link href='/drivers/onboarding?hasPaymentDetails=false'>
                <a>
                  <StatTile
                    testId='dashboard-missing-payment-details-tile'
                    title={t(
                      'default_dashboard.missing_payments_details_stats_title',
                    )}
                    description={t(
                      'default_dashboard.missing_payments_details_stats_description',
                    )}
                    colour='red'
                    secondaryColour='white'
                    value={stats.missingPaymentDetails}
                  />
                </a>
              </Link>
            </div>
          </div>
          <div className='mb-4 grid grid-cols-1 items-stretch gap-4 sm:grid-cols-2 xl:flex xl:flex-row'>
            <div className='flex-1' onClick={onboardingHandleClick}>
              <Link href={`/drivers/onboarding`}>
                <a>
                  <PieChartTile
                    testId='dashboard-onboarding-drivers-tile'
                    className='flex h-full flex-row'
                    title={t(
                      'default_dashboard.onboarding_applications_stats_title',
                    )}
                    percentage={stats.onboardingPercentage}
                    value={
                      typeof stats.totalApplications === 'number'
                        ? stats.totalApplications
                        : '-'
                    }
                    pieTitle={t(
                      'default_dashboard.onboarding_applications_stats_description',
                    )}
                    description={
                      <PieChartKey
                        keys={[
                          {
                            count: `${
                              stats?.initialOnboardingApplications ?? '-'
                            }`,
                            key: t(
                              'default_dashboard.onboarding_initial_applications_pie_description',
                            ),
                          },
                          {
                            count: `${
                              stats?.additionalOnboardingApplications ?? '-'
                            }`,
                            key: t(
                              'default_dashboard.onboarding_additional_applications_pie_description',
                            ),
                          },
                        ]}
                      />
                    }
                  />
                </a>
              </Link>
            </div>
            <div className='flex-1'>
              <PieChartTile
                testId='dashboard-payment-method-tile'
                className='flex h-full flex-row'
                title={t('default_dashboard.payment_method_stats_title')}
                percentage={stats.paymentMethod}
                pieTitle={t(
                  'default_dashboard.payment_method_stats_description',
                )}
                description={
                  <PieChartKey
                    keys={[
                      {
                        key: t(
                          'default_dashboard.payment_method_wise_pie_description',
                        ),
                      },
                      {
                        key: t(
                          'default_dashboard.payment_method_own_pie_description',
                        ),
                      },
                    ]}
                  />
                }
              />
            </div>
            <div className='flex-1'>
              <Link href={'/reports/utr-report'}>
                <a>
                  <PieChartTile
                    className='h-full'
                    testId='dashboard-utr-coverage-tile'
                    title={t('default_dashboard.utr_coverage_stats_title')}
                    percentage={stats.utrCoverage}
                    pieTitle={t(
                      'default_dashboard.utr_coverage_stats_description',
                    )}
                    description={
                      <PieChartKey
                        keys={[
                          {
                            key: t(
                              'default_dashboard.with_utr_coverage_pie_description',
                            ),
                          },
                          {
                            key: t(
                              'default_dashboard.without_utr_coverage_pie_description',
                            ),
                          },
                        ]}
                      />
                    }
                  />
                </a>
              </Link>
            </div>
            <AnimatePresence>
              {hasMigratedDriversStats && mode === 'WAP' ? (
                <motion.div
                  initial={{ scale: 0.8, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  exit={{ scale: 0.8, opacity: 0 }}
                  className='flex-1'
                >
                  <MigratedStatTile
                    testId='dashboard-migrated-drivers-tile'
                    className='h-80'
                    title={t('default_dashboard.migrated_drivers_stats_title')}
                    description={getDescription('migrated')}
                    colour='purple-dark'
                    value={stats.migratedDrivers}
                  />
                </motion.div>
              ) : null}
            </AnimatePresence>
          </div>
        </div>
        <div className='mt-4'>
          <PathTitle
            paths={[mcName, t('default_dashboard.driver_evidence')].filter(
              isDefined,
            )}
          />
          <EvidenceTiles stats={stats.evidence} />
        </div>
      </div>
    </Dashboard>
  )
}

export default React.memo(DefaultUserDashboard)
