import { isDefined } from '@wise/utils'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import Dashboard from '~shared/layouts/Dashboard/Dashboard'
import PathTitle from '~shared/layouts/Dashboard/PathTitle'

import { AppPage } from '~types'

const NetworkViewDashboard: AppPage = (): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Dashboard>
      <div className='flex w-full flex-col'>
        <div className='flex flex-1 flex-col'>
          <div className='mb-6 flex flex-row items-center justify-between'>
            <PathTitle
              noMargins
              paths={[t('path_titles.dashboard')].filter(isDefined)}
            />
            {/* <DashboardFilters /> */}
          </div>
        </div>
        <div className='flex h-full w-full items-center justify-center'>
          <div className='flex flex-col items-center justify-center p-3 text-center'>
            <h3 className='mb-3 text-4xl font-bold tracking-wider text-black opacity-40'>
              {t('network_dashboard.coming_soon')}
            </h3>
          </div>
        </div>
        {/* <NetworkDashboardTiles /> */}
      </div>
    </Dashboard>
  )
}

export default NetworkViewDashboard
