import { isDefined, isNonEmptyString } from '@wise/utils'

import { route } from '~generated/routes'
import UploadCloudSVG from '~shared/components/Icons/svg/cloud-upload.svg'

import { SetupOptionsType } from '@/dashboard/components/Dashboard/SetupOption'

type SetupOptionsProps = {
  mcNetworkName: string | null | undefined
  canImportDrivers: boolean
  bulkUploadEnabled: boolean
}

const getOnboardingDashboardSetupOptions = ({
  mcNetworkName,
  canImportDrivers,
  bulkUploadEnabled,
}: SetupOptionsProps): SetupOptionsType[] =>
  [
    {
      testId: 'set-up-payments-option',
      icon: '/images/forms.svg',
      href: route('/payments/kyc'),
      title: 'Set up payments',
      description: `${
        isNonEmptyString(mcNetworkName) ? mcNetworkName : 'Your Courier Network'
      } requires KYC checks on your business.`,
      buttonName: 'Go to KYC.',
    },
    {
      testId: 'add-users-option',
      icon: '/images/user-icon.svg',
      href: route('/settings/users'),
      title: 'Add users',
      description:
        'Add other users within your business to give them access to your Wise platform.',
      buttonName: 'Go to users.',
    },
    {
      testId: 'add-policies-option',
      icon: '/images/clipboard.svg',
      href: route('/settings/document-library'),
      title: 'Add policies',
      description:
        'You can add any policies you want adding to your driver onboarding process.',
      buttonName: 'Go to policies.',
    },
    bulkUploadEnabled
      ? {
          testId: 'add-drivers-option',
          icon: '/images/driving-licence.svg',
          href: route('/settings/bulk-upload'),
          title: 'Add drivers',
          description:
            'We need some basic data about your drivers to set them up on the system.',
          buttonName: 'Upload drivers.',
          buttonIcon: <UploadCloudSVG height={18} width={18} />,
          isDisabled: !canImportDrivers,
        }
      : null,
  ].filter(isDefined)

export default getOnboardingDashboardSetupOptions
