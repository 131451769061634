import { isNonEmptyString } from '@wise/utils'
import produce from 'immer'
import create from 'zustand'

import { BasePlatform } from '@/platforms/hooks/usePlatform'

export type SessionState = {
  wap: {
    viewPlatform: BasePlatform | null
    viewLocation: string | null
  }
}

interface SessionStateStore {
  state: SessionState

  setWapViewPlatform: (value: BasePlatform | null) => void
  setWapViewLocation: (value: string | null) => void
}

export const useSessionState = create<SessionStateStore>((set) => ({
  state: {
    wap: {
      viewPlatform: null,
      viewLocation: null,
    },
  },

  setWapViewPlatform: (value) =>
    set(
      produce<SessionStateStore>((draft) => {
        if (draft.state.wap.viewPlatform === value) return
        draft.state.wap.viewPlatform = value
        draft.state.wap.viewLocation = null
      }),
    ),
  setWapViewLocation: (value) =>
    set(
      produce<SessionStateStore>((draft) => {
        draft.state.wap.viewLocation = isNonEmptyString(value) ? value : null
      }),
    ),
}))
