import { useOnboardingDashboardQuery } from '@wise/graphql'
import { isNonEmptyString } from '@wise/utils'
import { motion } from 'framer-motion'
import React from 'react'

import { useFeatureFlags } from '~shared/featureFlags'
import Dashboard from '~shared/layouts/Dashboard/Dashboard'

import getOnboardingDashboardSetupOptions from '@/dashboard/components/Dashboard/OnboardingDashboardSetupOptions'
import SetupOption from '@/dashboard/components/Dashboard/SetupOption'
import { useMaincontractor } from '@/maincontractors/hooks/useMaincontractor'
import Header from '@/mc-onboarding/components/Header'
import Title from '@/mc-onboarding/components/Title'
import { ONBOARDING_SUPPORT_EMAIL } from '@/mc-onboarding/consts/email'

import { AppPage } from '~types'

const OnboardingDashboard: AppPage = () => {
  const mc = useMaincontractor()
  const mcId = mc?.id
  const bulkUploadEnabled = useFeatureFlags('bulkUploadEnabled')

  const { data } = useOnboardingDashboardQuery(
    isNonEmptyString(mcId)
      ? {
          variables: { mcId },
        }
      : { skip: true },
  )

  const canImportDrivers = React.useMemo(
    () =>
      (data?.mainContractor?.onboardingConfigurations || []).some(
        (config) => config.active,
      ),
    [data?.mainContractor?.onboardingConfigurations],
  )

  const setupOptions = React.useMemo(
    () =>
      getOnboardingDashboardSetupOptions({
        mcNetworkName: mc?.mcOnboardingData?.network?.name,
        canImportDrivers,
        bulkUploadEnabled,
      }),
    [bulkUploadEnabled, canImportDrivers, mc?.mcOnboardingData?.network?.name],
  )

  return (
    <Dashboard>
      <div className='flex h-full  justify-center pt-16 pb-3 sm:items-start sm:pt-9'>
        <div className='min-w-250 sm:min-w-550 mx-4 flex max-w-700 flex-col content-center items-stretch justify-center sm:max-w-[100vw]'>
          <Header testId='onboarding-dashboard-header' className='sm:mb-0'>
            <Title
              titleText1='Your system is '
              titleText2='limited pending setup.'
            />
          </Header>
          <h2 className='mb-9 text-center text-xl tracking-normal sm:mb-12 sm:tracking-wider'>
            By adding documents, our team can process your setup faster and
            unlock your system.
          </h2>
          <div className='min-w-250 flex w-full max-w-700 flex-col items-center justify-center sm:min-w-400 sm:max-w-[100vw]'>
            <div className='mb-6 sm:mb-0'>
              <div className='flex flex-col flex-wrap content-center justify-center gap-x-6 sm:flex-row'>
                {setupOptions.map((step, ix) => {
                  return (
                    <SetupOption
                      key={ix}
                      testId={step.title}
                      icon={step.icon}
                      title={step.title}
                      description={step.description}
                      buttonName={step.buttonName}
                      buttonIcon={step.buttonIcon}
                      href={step.href}
                      handleSubmit={step.handleSubmit}
                      customButton={step.customButton}
                      isDisabled={step.isDisabled}
                    />
                  )
                })}
              </div>
            </div>
          </div>
          <div className='justfiy-center flex flex-col items-center gap-y-2'>
            <h2 className='mt-6 text-center text-lg font-extrabold'>
              Having trouble?
            </h2>
            <p className='text-center'>
              Our team is always on hand to offer help - if you&apos;ve got any
              questions relating to your Wise system, please get in touch.
            </p>
            <a href={`mailto:${ONBOARDING_SUPPORT_EMAIL}`}>
              <motion.button
                whileTap={{ scale: 0.96 }}
                className='mt-4 rounded-lg border-[1px] border-solid border-black px-3 py-2 font-extrabold shadow-darken-sm hover:shadow-md'
              >
                Contact us.
              </motion.button>
            </a>
          </div>
        </div>
      </div>
    </Dashboard>
  )
}

export default OnboardingDashboard
